import React from 'react';

import { useStaticQuery, graphql, Link } from "gatsby"


const ProjectListing = ({projectCount}) => {

        const ProjectData = useStaticQuery(graphql`
            query ProjectQuery {
                allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "web"}}}}) {
                    edges {
                        node {
                            id
                            slug
                            title
                            featured_media{
                                localFile{
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `)

    const projects = ProjectData.allWordpressPost.edges;
    
    let projectList = [];
    if(projectCount === "-1"){
        projectList = projects;
    } else{
        projectList = projects.slice(0,projectCount);
    }
        
        return <div className="project-listing-container">
            <ul className="project-listing">
            {
                
                projectList.map(function(project, i) {
                    return (
                        <li key={i} className="project-preview">
                            <Link to={project.node.slug} className="project-link" title={`View the ${project.node.title} project`}>
                                <div className="project-image" style={{ backgroundImage: `url(${project.node.featured_media.localFile.publicURL})` }} />
                                <span>View Project</span>
                            </Link>
                        </li>
                    )
                })

            }
            </ul>

        </div>;
    }



ProjectListing.propTypes = {
    projectCount: ``
}

export default ProjectListing;