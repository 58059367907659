import React from "react"

import Layout from "../components/layout"
import ProjectListing from "../components/projectListing"
import SEO from "../components/seo"

const ProjectsPage = () => (

    <Layout>
        <SEO title="Projects" />
        <section id="welcomeSection" className="section-description">
            <h1>Projects</h1>
            <p>Below are some of the projects that I have worked on.</p>
        </section>
        <ProjectListing projectCount="-1"/>
    </Layout>

)

export default ProjectsPage